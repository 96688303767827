import Vue from 'vue'
import App from './App.vue'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import VueQuillEditor from 'vue-quill-editor'
import SkuForm from 'vue-sku-form'
import 'quill/dist/quill.core.css' // 引入样式
import 'quill/dist/quill.snow.css' // snow theme
import 'quill/dist/quill.bubble.css' // bubble theme
import '../public/ueditor/ueditor.config.js'
import '../public/ueditor/ueditor.all.js'
import '../public/ueditor/lang/zh-cn/zh-cn.js'
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
// 导入vuex实例对象

// 图标库
// import eIconPicker from 'e-icon-picker';
// import 'e-icon-picker/dist/index.css'; // 如果需要默认样式
// import "e-icon-picker/icon/default-icon/symbol.js"; //基本彩色图标库
// import 'e-icon-picker/index.css'; // 基本样式，包含基本图标
// import 'font-awesome/css/font-awesome.min.css'; //font-awesome 图标库
// Vue.use(eIconPicker)

import EIconPicker from 'e-icon-picker';
import 'e-icon-picker/lib/index.css'; // 如果需要默认样式
// import 'font-awesome/css/font-awesome.min.css'; // 如果需要 Font Awesome 支持

Vue.use(EIconPicker, {
	FontAwesome: false, // 使用 Font Awesome 图标库
	ElementUI: true, // 使用 Element UI 图标库
	eIcon: true, // 使用 eIcon 图标库
	eIconSymbol: true, // 使用 symbol 图标库
	addIconList: [], // 自定义图标列表
	removeIconList: [] // 移除的图标列表
});





// 下拉树形选择
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('treeselect', Treeselect)

// 自定义指令
import permissionDirective from './utils/permission';
Vue.directive('permission', permissionDirective);


Vue.use(Element, {
	// size: Cookies.get('size') || 'small', // set element-ui default size
	size: 'small', // set element-ui default size,
	// i18n: (key, value) => i18n.t(key, value)
})

Vue.use(VueQuillEditor, /* { default global options } */ )
Vue.config.productionTip = false
Vue.use(ViewUI)
Vue.use(SkuForm)
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')